import jquery from 'jquery'
import ct from 'countries-and-timezones'


jquery('html')

const methodsTimezones = {
    clientMethod1: function () {
        return JSON.stringify({
            name: Intl.DateTimeFormat().resolvedOptions().timeZone.toString()
        }, null, 4)
    }, 


    clientMethod2: function () {
        const offset = (new Date()).getTimezoneOffset();
        
        addLog(`Offset is ${offset}`)
        
        const possibleTimezones = findTimezonesWithOffset(offset);
        return `\npossibleTimezones are \n${possibleTimezones.join(',\n')}`
    },


    serverMethod1: async function () {
        const workerURL = '/api/ipinfo';
        const response = await fetch(workerURL)
        const json = await response.json()

        
        return `
            Timezone from server is ${json.timezone}
        `
    }
}


async function init () {
    addLog('Hi, I am getting timezones!')
    
    for (const [methodName, methodFn] of Object.entries(methodsTimezones)) {
        addLog(`Timezone From Method ${methodName}`)
        const data = await methodFn()
        addLog(data)
    }

    
    addLog('All done!')
}


function addLog (textItem) {
    const codeLogsElement = document.querySelector('#codelogs')
    
    const col = document.createElement('col')
    const codeRow = document.createElement('code')
    const preRow = document.createElement('pre')
    codeRow.innerText = textItem

    preRow.appendChild(codeRow)
    col.appendChild(preRow)
    codeLogsElement.appendChild(col)
}



function findTimezonesWithOffset (offset) {
    return Object.values(ct.getAllTimezones())
    .filter(timezone => timezone.utcOffset === offset)
    .map(timezone => timezone.name)
}

init().catch(console.error)